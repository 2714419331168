/**
 * This is part of `babel-inline-constants-plugin` specially created to works
 * with Hot-Module Replacement (HMR). To watch changes of a new json of texts,
 * import it here and store into `jsons` object.
 */
import actions from 'texts/actions.json';
import breadcrumbs from 'texts/breadcrumbs.json';
import descriptions from 'texts/descriptions.json';
import empty from 'texts/empty.json';
import errors from 'texts/errors.json';
import inputs from 'texts/inputs.json';
import loading from 'texts/loading.json';
import seo from 'texts/seo.json';
import subtitles from 'texts/subtitles.json';
import successes from 'texts/successes.json';
import switches from 'texts/switches.json';
import tips from 'texts/tips.json';
import titles from 'texts/titles.json';
import toasts from 'texts/toasts.json';

const jsons = {
  actions,
  breadcrumbs,
  descriptions,
  empty,
  errors,
  inputs,
  loading,
  seo,
  subtitles,
  successes,
  switches,
  tips,
  titles,
  toasts,
};

const separateFileFromKeys = (arg) => {
  const [file, ...keys] = arg.split('.').map((s) => s.trim());

  return { file, keys };
};

const placeholder = (path) => {
  const { file, keys } = separateFileFromKeys(path);
  const availableConstants = jsons[file];

  if (!availableConstants) {
    throw new Error(`file ${file} is empty!`);
  }

  let tmp = availableConstants;

  for (const key of keys) {
    tmp = tmp[key] || null;
  }

  if (!tmp) {
    throw new Error(`constant [${path}] was not found!`);
  }

  return tmp;
};

export default placeholder;
